@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .header-active {
    @apply whitespace-nowrap flex h-14 w-full md:w-fit items-center cursor-pointer md:text-white justify-start md:justify-center bg-[#7971ea] px-3 py-3 md:py-2 transition-all duration-300;
  }
  .header-inactive {
    @apply whitespace-nowrap flex h-14 w-full md:w-fit items-center text-black md:text-white justify-start md:justify-center cursor-pointer hover:bg-[#7971ea] hover:bg-opacity-50 hover:text-white px-3 py-3 md:py-2 transition-all duration-300;
  }

  .btn {
    @apply flex items-center justify-center px-3 py-2 hover:opacity-50 rounded-md space-x-1 disabled:opacity-50;
  }
  .btn-primary {
    @apply text-white bg-orange-600;
  }
  .btn-secondary {
    @apply text-white bg-orange-600;
  }
  .form-wrapper {
    @apply flex flex-col space-y-3 w-full;
  }
  .form-wrapper form {
    @apply flex flex-col space-y-3;
  }
  .form-wrapper label {
    @apply flex flex-col space-y-1;
  }
  .form-wrapper input,
  .form-wrapper textarea {
    @apply px-3 py-2 outline-none border rounded-md;
  }
  .form-wrapper button {
    @apply mr-auto py-2 px-3 bg-orange-500 text-white rounded-md cursor-pointer disabled:opacity-50 hover:opacity-50;
  }
  .response {
    @apply p-3 bg-orange-300 bg-opacity-20 my-3 rounded-md whitespace-pre-wrap;
  }
  .text-wrapper {
    @apply flex flex-col space-y-3;
  }
  .text-wrapper h3 {
    @apply text-2xl;
  }
  .text-wrapper h4 {
    @apply text-lg;
  }
  .text-wrapper ul {
    @apply list-disc mx-4 space-y-3;
  }
}

.sidebarContainer {
  position: fixed;
  top: 3.5rem;
  left: -200px;
  z-index: 50;
  width: 200px;
  height: calc(100% - 3.5rem);
  background-color: white;
  overflow-y: auto;
  transition: left 0.3s;
}

.sidebarContainer.show {
  left: 0;
}

.menuItemsWrapper {
  height: 100%;
  overflow-y: auto;
}

.overlay {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}

.overlay.show {
  opacity: 1;
  pointer-events: all;
}

.noScroll {
  overflow: hidden;
  height: 100%;
}

.fade-out::after {
  content: "";
  display: block;
  height: 50%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, white, transparent);
}
